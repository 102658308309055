import React from "react";
import styled from "styled-components";
import HeaderImage from "../images/branding/banner.png";
import HeaderSmall from "../images/branding/banner_small.png";

const Image = styled.img`
  width: 100%;
  max-width: 960px;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Container = styled.div`
  display: block;

  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 480px) {
    min-height: 150px;
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Header = () => {
  return (
    <Container>
      <a href="/">
        {window.innerWidth > 480 ? (
          <Image src={HeaderImage} alt="c t illustrations banner" />
        ) : (
          <Image src={HeaderSmall} alt="c t illustrations banner" />
        )}
      </a>
    </Container>
  );
};

export default Header;
