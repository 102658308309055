import React, { useEffect } from "react";
import "./App.css";
import styled from "styled-components";
import Header from "./components/header";
import Bio from "./components/bio";
import Slider from "./components/slider/slider";
import Socials from "./components/socials";
import Contact from "./components/contactModal";
import Footer from "./components/footer";
import Portfolio from "./components/portfolio";
import Shop from "./components/shop";
import Pricing from "./components/pricing";
import Burger from "./components/multilevel-dropdown-menu/burger";
import ScrollTop from "./components/multilevel-dropdown-menu/scrollTopButton";

import LogoImage from "./images/branding/hello.png";

const Page = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
`;

const Logo = styled.img`
  width: 50vw;
  @media only screen and (min-width: 480px) {
    width: 40vw;
  }
  @media only screen and (min-width: 768px) {
    width: 50vw;
  }
  @media only screen and (min-width: 1024px) {
    width: 30vw;
  }
  @media only screen and (min-width: 1600px) {
    width: 15vw;
  }
`;
const Acknowledgement = styled.div`
  width: 95%;
  font-size: 0.8em;
  text-align: center;
  margin: 5px 0;
  max-width: 900px;
  @media only screen and (min-width: 480px) {
    width: 60vw;
  }
  @media only screen and (min-width: 768px) {
    width: 60vw;
  }
  @media only screen and (min-width: 1024px) {
    width: 60vw;
  }
  @media only screen and (min-width: 1600px) {
    width: 60vw;
  }
`;

function App() {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <>
      <Page>
      <div id="top" />
        <Header/>
        <ScrollTop/>
        <Acknowledgement>
          We acknowledge and pay our respects to the traditional custodians of
          the lands and waters of the Gumbaynggirr, and all Aboriginal Elders,
          past, present, and emerging.
        </Acknowledgement>
        <Burger />
        <Slider />
        <Logo src={LogoImage} />
        <Socials />
        <Contact />
        <div id="about" />
        <Bio />
        <div id="port" />
        <Portfolio />
        <Shop />
        <div id="pricing" />
        <Pricing />
        <Footer />
        <div id="contact" />
      </Page>
    </>
  );
}

export default App;
