import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin: 15px auto;
  background: #f8838a40;
`;

const Title = styled.h1`
  text-align: center;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Text = styled.p`
  width: 90vw;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 999px;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Bio = () => {
  return (
    <Container>
      <Title>About CT</Title>
      <Text>
        Crystal Tate is an Australian artist based in the beautiful town of
        Urunga, NSW. She is passionate about creating images that generate
        feelings of joy, fun and connection. Her work is refreshing, whimsical
        and uplifting.
        <br />
        She is self taught and her favourite medium is watercolour, acrylic,
        pens and ink.
      </Text>
      <Text>
        Crystal has always loved drawing and pursued any opportunity to do so
        through her childhood and early adulthood. Her work "Contemplation" was
        selected in the 2003 Art Express exhibition and displayed in David
        Jones. After a regional tour of New South Wales, Art express offered to
        purchase the work but it had already been promised to a very important
        art collector- Crystal's mum.{" "}
      </Text>
      <Text>
        After school Crystal travelled in Europe and then settled in Brisbane,
        QLD. Her first attempt at exhibiting work as a freelance artist was in a
        local cafe- every piece created sold out. It was affirming and
        flattering but at this point, the idea of pursuing art as a career felt
        too indulgent and insecure so Crystal commenced her Nursing Degree.
        Whilst studying, Crystal enjoyed several artistic flourishes, always met
        with positive results and enthusiasm. Many moons later, while talking to
        a friend in the creative industries, she realised the truth. She was an
        artist. Not only did it light her up with happiness, but it also spread
        that joy to others.
      </Text>
      <Text>
        As a nurse she had always strived to make someone's worst day just a
        little bit better and now, through her art she saw she could create
        something that would bring delight everyday. Crystal's works often focus
        on the minutiae of life, the moments accessible to everyone that make us
        feel a little lighter, more connected, less stressed and more content.{" "}
      </Text>
      <Text>
        Crystal has original works for sale- follow along on Instagram to stay
        up to date with these.
      </Text>
      <Text>
        For prints and fun products, head to{" "}
        <a
          href="https://society6.com/crystaltateillustrations"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black"}}
        >
          Society6
        </a>
      </Text>
      <Text>
        Want something personal?
        <br />
        Get in touch. Crystal loves capturing the people we love most in
        illustrated portraits and also painting places that connect us to
        special moments and memories.
      </Text>
      <Text>
        You can also find her on Spoonflower if you wish to purchase beautiful
        fabric covered in her quirky illustrations.
      </Text>
      <Text>
        Want to collaborate? Contact me!
        <br />
        <a
          href="mailto: helloctillustrations@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black"}}
        >
          helloctillustrations@gmail.com
        </a>
      </Text>
      <Text></Text>
    </Container>
  );
};

export default Bio;
