import React, { useState } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";

// import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";

const Container = styled.div`
  display: block;

  margin-left: auto;
  margin-right: auto;
`;

const Box = styled.div`
  display: block;

  margin-left: auto;
  margin-right: auto;
  width: 90vw;
  max-width: 380px;
  background: white;
  border-radius: 5px;
  box-shadow: 5px 5px 5px black;
  padding: 2px;
  margin-top: 100px;
`;

const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
`;

const Input = styled.input`
  width: 100px;
  display: block;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const Close = styled.span`
  position: fixed;
  width: 10px;
  height: 10px;
  right: 26px;
  top: 106px;
`;

const Button = styled.button`
  background-color: #a2b7b3;
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const Contact = () => {
  const [contact, setContact] = useState({
    name: "",
    reply_to: "",
    phone: "",
    message: "",
  });
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setContact((prev) => ({ ...prev, [name]: value }));
    e.preventDefault();
  };

  function sendForm() {
    console.log(contact);
    emailjs
      .send(
        "service_CTIllustrationss",
        "template_CTIllustrations",
        contact,
        "lC3qN2NqNa13VitI4"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
    resetContact();
    handleClose();
  }

  function resetContact() {
    setContact({ name: "", reply_to: "", phone: "", message: "" });
  }

  return (
    <Container>
      <Button variant="contained" onClick={handleOpen}>
        Lets Chat!
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Close onClick={handleClose}>X</Close>
          <Form onSubmit={handleSubmit(sendForm)}>
            <input type="hidden" name="contact_number" />
            <Label>
              Name:
              <input
                required
                type="text"
                name="name"
                id="name"
                placeholder="required"
                value={contact.name}
                onChange={handleChange}
              />
            </Label>
            <br />
            <Label>
              Email:
              <input
                required
                type="email"
                name="reply_to"
                id="reply_to"
                placeholder="required"
                value={contact.reply_to}
                onChange={handleChange}
              />
            </Label>
            <br />
            <Label>
              Number:
              <input
                type="tel"
                name="phone"
                id="phone"
                placeholder="optional"
                value={contact.phone}
                onChange={handleChange}
              />
            </Label>
            <br />
            <Label>
              Message:
              <textarea
                required
                name="message"
                id="message"
                placeholder="required"
                value={contact.message}
                onChange={handleChange}
                cols="40"
                rows="10"
              ></textarea>
            </Label>
            <Input type="submit" value="Send" />
          </Form>
        </Box>
      </Modal>
    </Container>
  );
};

export default Contact;
