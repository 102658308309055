import React from "react";
import styled from "styled-components";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import bottle1 from "../../images/samples/bottle01.jpg";
import bottle2 from "../../images/samples/bottle02.jpg";
import bottle3 from "../../images/samples/bottle03.jpg";
import bottle4 from "../../images/samples/bottle04.jpg";

import book1 from "../../images/samples/book01.jpg";
import book2 from "../../images/samples/book02.jpg";
import book3 from "../../images/samples/book03.jpg";
import book4 from "../../images/samples/book04.jpg";

import framed1 from "../../images/samples/landscape01.jpg";
import framed3 from "../../images/samples/landscape02.jpg";
import framed2 from "../../images/samples/landscape03.jpg";
import framed4 from "../../images/samples/landscape04.jpg";

import portrait1 from "../../images/samples/portrait01.jpg";
import portrait3 from "../../images/samples/portrait02.jpg";
import portrait2 from "../../images/samples/portrait03.jpg";
import portrait4 from "../../images/samples/portrait04.jpg";

import landscape1 from "../../images/samples/land01.jpg";
import landscape3 from "../../images/samples/land02.jpg";
import landscape2 from "../../images/samples/land03.jpg";
import landscape4 from "../../images/samples/land04.jpg";

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const SliderGroup = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  max-width: 999px;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const SliderImage = styled.img`
  max-width: 49%;
  padding: 1px;
  @media only screen and (min-width: 480px) {
    max-width: 24%;
  }
  @media only screen and (min-width: 768px) {
    max-width: 24%;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Slider = () => {
  return (
    <>
      <AliceCarousel
        mouseTracking
        autoPlay
        infinite
        autoPlayInterval="4000"
        // items={items}
        controlsStrategy="alternate"
      >
        <Container className="item" data-value="0">
          <SliderGroup>
            <SliderImage src={bottle1} />
            <SliderImage src={bottle2} />
            {window.innerWidth > 480 && (
              <>
                <SliderImage src={bottle3} />
                <SliderImage src={bottle4} />
              </>
            )}
          </SliderGroup>
        </Container>
        <Container className="item" data-value="1">
          <SliderGroup>
            <SliderImage src={book1} />
            <SliderImage src={book2} />
            {window.innerWidth > 480 && (
              <>
                <SliderImage src={book3} />
                <SliderImage src={book4} />
              </>
            )}
          </SliderGroup>
        </Container>
        <Container className="item" data-value="2">
          <SliderGroup>
            <SliderImage src={framed1} />
            <SliderImage src={framed2} />
            {window.innerWidth > 480 && (
              <>
                <SliderImage src={framed3} />
                <SliderImage src={framed4} />
              </>
            )}
          </SliderGroup>
        </Container>
        <Container className="item" data-value="3">
          <SliderGroup>
            <SliderImage src={portrait1} />
            <SliderImage src={portrait2} />
            {window.innerWidth > 480 && (
              <>
                <SliderImage src={portrait3} />
                <SliderImage src={portrait4} />
              </>
            )}
          </SliderGroup>
        </Container>
        <Container className="item" data-value="4">
          <SliderGroup>
            <SliderImage src={landscape1} />
            <SliderImage src={landscape2} />
            {window.innerWidth > 480 && (
              <>
                <SliderImage src={landscape3} />
                <SliderImage src={landscape4} />
              </>
            )}
          </SliderGroup>
        </Container>
      </AliceCarousel>
    </>
  );
};

export default Slider;
