import React from "react";
import styled from "styled-components";

// import Button from "@material-ui/core/Button";

const Container = styled.div`
  display: flex;

  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: #a2b7b340;
  width: 100%;
  height: 25vh;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Button = styled.button`
  background-color: #eeb500;
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const Shop = () => {
  return (
    <Container>
      <a
        href="https://society6.com/crystaltateillustrations"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button>Shop selection at Society6</Button>
      </a>
    </Container>
  );
};

export default Shop;
