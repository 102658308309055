import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin: 15px auto;
`;

const Title = styled.h1`
  width: 99vw;
  text-align: center;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Text = styled.p`
  width: 95vw;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 999px;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;
const Disclaimer = styled.p`
  width: 90vw;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: .7em;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Pricing = () => {
  return (
    <Container>
      <Title>Portrait Pricing & Order Info </Title>

      <Text>
        A unique and meaningful gift. Perfect to celebrate birthdays,
        anniversaries and milestones but also a beautiful way to remember those
        no longer with us. Using a combination of water-colour and artists pens,
        I interpret your reference photos in my creative style.{" "}
      </Text>

      <Text>
        Your booking includes the original analog artwork on 310lb cold pressed
        paper. Size is A4. If you wish to discuss a different size, a simple
        background or another idea - please contact me for a quote.
      </Text>

      <Text>
        Once you have reserved and purchased a custom spot on my schedule, your
        portrait will be completed within 4 weeks of receiving the reference
        photo. Reference photos are to be sent to me via email. Illustrations
        are based on ONE reference photo (more can be included for an additional
        charge) and take the full month they are booked for. For this reason,
        booking in advance is essential. I take on a limited number of custom
        clients per month. You are welcome to book and purchase a custom
        portrait spot in advance to make sure you don’t miss out.{" "}
      </Text>

      <Text>My rates are in AU$ and per item. </Text>

      <Text>
        To estimate the cost of your illustration - select the items you will
        need, adjust the quantity if needed and add together (plus shipping).
        For example: 2 Adults (2 x AU$80) + 2 children (2 x AU$55) +1 pet (1x
        AU$55) = AU$325 + shipping.{" "}
      </Text>

      <Text>Original Illustrated Portrait Characters:</Text>
      <Text>
        Adult | AU$ 80 
        <br/>
        Child | AU$ 55 
        <br/>
        Newborn* | AU$ 30 
        <br/>
        Pet | AU$ 55
        <br/>
        Original Head and Neck Illustrated Portrait | AU$80 per person
        <br/>
        Extra reference photo | $10 per photo
        <br/>
        <br/>
        *swaddled baby in
        blanket
      </Text>

      <Text>
        Your order includes ONE REFERENCE PHOTO to base the illustration on. If
        you require me to use multiple photos to use as reference - be sure to
        add it to your order.
      </Text>

      <Text>RATES: (as of September 2022) per item / in Australian Dollars</Text>

      <Disclaimer>
        All Artwork Is The Sole Property Of Crystal Tate And Is Held Under
        Copyright (Even After Purchase ). The Images, Artwork, And Contents Of
        This Website May Not Be Copied, Collected, Or Used For Personal Or
        Professional Gain Without The Written Permission From Crystal Tate. All
        Images Of Artwork, Sold Or Otherwise, Are Retained By Crystal Tate .{" "}
      </Disclaimer>
    </Container>
  );
};

export default Pricing;
