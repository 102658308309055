import React from "react";
import styled from "styled-components";

import facebook from "../images/socials/fb_logo.png";
import insta from "../images/socials/insta_logo.png";
import pinterest from "../images/socials/pinterest_logo.png"

const Container = styled.div`
  display: block;

  margin-left: auto;
  margin-right: auto;
  background: #eeb50040;
  width: 100%;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const FootSection = styled.div`
  margin: 15px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;
const SmallFootSection = styled.div`
  margin: 15px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-size: .7em;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const FootLink = styled.a`
  text-decoration: none;
  color: black;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: centre;

  width: 90vw;
  margin: 15px 0;
`;

const SocialLink = styled.a`
margin: auto;
display: flex;
justify-content: center;
`;

const Icon = styled.img`
width: 45px;
`;

const Footer = () => {
  return (
    <Container>
      
      <FootSection>
        <FootLink href="mailto: helloctillustrations@gmail.com">
          Email: <u>helloctillustrations@gmail.com</u>
        </FootLink>
      </FootSection>
      <FootSection>
      <FlexContainer>          
          <SocialLink
            href="https://www.instagram.com/crystaltateillustrations/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon src={insta} alt="instagram logo"></Icon>
          </SocialLink>
          <SocialLink
            href="https://www.facebook.com/C-T-Illustrations-101024289404002"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon src={facebook} alt="facebook logo"></Icon>
          </SocialLink>
          
          <SocialLink
            href="https://pin.it/lySdxBB"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon src={pinterest} alt="pintrest logo"></Icon>
          </SocialLink>
      </FlexContainer>
      </FootSection>
      <SmallFootSection>Created and hosted by HolisTechAus &copy;</SmallFootSection>
    </Container>
  );
};

export default Footer;
